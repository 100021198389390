import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

interface useWalletLiquidityProps {
  token0Reserve?: BigNumber;
  token1Reserve?: BigNumber;
  walletBalance?: BigNumber;
  totalSupply?: BigNumber;
}

export const useWalletLiquidity = ({
  token0Reserve,
  token1Reserve,
  walletBalance,
  totalSupply,
}: useWalletLiquidityProps) => {
  const [reserveALiquidity, setReserveALiquidity] = useState<
    BigNumber | undefined
  >(undefined);
  const [reserveBLiquidity, setReserveBLiquidity] = useState<
    BigNumber | undefined
  >(undefined);

  useEffect(() => {
    if (
      token0Reserve &&
      !token0Reserve?.isZero() &&
      token1Reserve &&
      !token1Reserve?.isZero() &&
      walletBalance &&
      !walletBalance?.isZero() &&
      totalSupply &&
      !totalSupply?.isZero()
    ) {
      setReserveALiquidity(token0Reserve.mul(walletBalance).div(totalSupply));
      setReserveBLiquidity(token1Reserve.mul(walletBalance).div(totalSupply));
    }
  }, [token0Reserve, token1Reserve, walletBalance, totalSupply]);

  return [reserveALiquidity, reserveBLiquidity];
};
