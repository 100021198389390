import { useCallback, useRef } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

export const useInterval = (callback: () => void, delay: number | null) => {
  const callbackRef = useRef(callback);
  const intervalRef = useRef<NodeJS.Timer>();

  useIsomorphicLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const tick = () => {
    callbackRef.current();
  };

  useIsomorphicLayoutEffect(() => {
    if (delay !== null) {
      const id = setInterval(tick, delay);
      intervalRef.current = id;
      return () => clearInterval(id);
    }

    clearInterval(intervalRef.current);
  }, [delay]);

  const resetInterval = useCallback(() => {
    if (delay !== null) {
      clearInterval(intervalRef.current);
      const id = setInterval(tick, delay);
      intervalRef.current = id;
    }
  }, [delay]);

  return { resetInterval };
};
