import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";

export const substractSlippage = (
  value: BigNumber,
  slippageTolerance: number
): BigNumber => {
  const substractValue = value.mul(slippageTolerance * 100).div(10000);
  return value.sub(substractValue);
};

export const perecentFromBigNumbers = (
  value: BigNumber,
  max: BigNumber,
  decimals?: number
): number => {
  const _value = parseFloat(formatUnits(value, decimals));
  const _max = parseFloat(formatUnits(max, decimals));
  return Number(((_value * 100) / _max).toFixed());
};
