import { BigNumber } from "ethers";
import { useCallback, useReducer } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import {
  defaultState,
  InputValueActionKind,
  inputValueReducer,
} from "./reducer";

export const useInputValue = (
  value: string,
  decimals: number = 18
): [string, BigNumber, BigNumber, (value: string) => void] => {
  const { transactionSettings } = useGlobalContext();

  const [values, dispatch] = useReducer(inputValueReducer, {
    ...defaultState,
    tokenValue: value,
  });

  const setTokenValue = useCallback(
    (value: string) =>
      dispatch({
        type: InputValueActionKind.UPDATE,
        payload: {
          value,
          decimals,
          slippageTolerance: transactionSettings.slippageTolerance,
        },
      }),
    [decimals, transactionSettings.slippageTolerance]
  );

  return [
    values.tokenValue,
    values.tokenValueBN,
    values.tokenMinValueBN,
    setTokenValue,
  ];
};
