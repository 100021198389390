import { useRef } from "react";
import { useEventListener } from "usehooks-ts";

type useVisibilityChangeParams = {
  onHide?: () => void;
  onShow?: () => void;
};

export const useVisibilityChange = (params: useVisibilityChangeParams = {}) => {
  const { onHide = () => {}, onShow = () => {} } = params;

  const documentRef = useRef<Document>(
    typeof window !== "undefined" ? document : null
  );

  useEventListener(
    "visibilitychange",
    () => {
      const isHidden = documentRef.current?.hidden;
      isHidden ? onHide() : onShow();
    },
    documentRef
  );
};
