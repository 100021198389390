import { BigNumber, FixedNumber } from "ethers";
import { useEffect, useState } from "react";

interface useWalletPoolShareProps {
  walletBalance?: BigNumber;
  totalSupply?: BigNumber;
}

export const useWalletPoolShare = ({
  walletBalance,
  totalSupply,
}: useWalletPoolShareProps): string | undefined => {
  const [poolShare, setPoolShare] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      !walletBalance ||
      walletBalance.isZero() ||
      !totalSupply ||
      totalSupply.isZero()
    ) {
      setPoolShare(undefined);
      return;
    }

    const userTokens = FixedNumber.from(walletBalance);
    const poolTokens = FixedNumber.from(totalSupply);

    setPoolShare(
      userTokens
        .divUnsafe(poolTokens)
        .mulUnsafe(FixedNumber.from("100"))
        .round(3)
        .toString()
    );
  }, [walletBalance, totalSupply]);

  return poolShare;
};
