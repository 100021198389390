import { AddressZero, Zero } from "@ethersproject/constants";
import { Hash } from "@wagmi/core";
import { ABIS } from "constants/abis";
import { Token } from "core/types";
import { useMemo } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { useContractRead } from "wagmi";
import { useWalletLiquidity, useWalletPoolShare } from "_hooks";

export const useReserves = ({
  pairAddress,
  polygonPairAddress,
  token0,
  token1,
}: {
  pairAddress?: Hash;
  polygonPairAddress?: Hash;
  token0?: Token;
  token1?: Token;
}) => {
  const { address } = useGlobalContext();

  const usePolygon =
    (!pairAddress || pairAddress === AddressZero) &&
    polygonPairAddress &&
    polygonPairAddress !== AddressZero;

  const {
    data: accountLiquidityTokenBalance,
    refetch: refetchAccountLiquidityTokenBalance,
  } = useContractRead({
    address: pairAddress,
    abi: ABIS.PAIR,
    functionName: "balanceOf",
    enabled: Boolean(address && pairAddress && pairAddress !== AddressZero),
    args: [address ?? AddressZero],
  });

  const {
    data: liquidityTokenTotalSupply,
    refetch: refetchLiquidityTokenTotalSupply,
  } = useContractRead({
    address: pairAddress,
    abi: ABIS.ERC20,
    functionName: "totalSupply",
    enabled: Boolean(pairAddress && pairAddress !== AddressZero),
  });

  const {
    data: liquidityTokenReserves,
    refetch: refetchLiquidityTokenReserves,
  } = useContractRead({
    address: usePolygon ? polygonPairAddress : pairAddress,
    abi: ABIS.PAIR,
    functionName: "getReserves",
    enabled: Boolean(
      (pairAddress && pairAddress !== AddressZero) ||
        (polygonPairAddress && polygonPairAddress !== AddressZero)
    ),
  });

  const [pairToken0, _] = [token0?.address, token1?.address].sort();

  const token0Reserves = liquidityTokenReserves
    ? pairToken0 === token0?.address
      ? liquidityTokenReserves[0]
      : liquidityTokenReserves[1]
    : Zero;

  const token1Reserves = liquidityTokenReserves
    ? pairToken0 === token0?.address
      ? liquidityTokenReserves[1]
      : liquidityTokenReserves[0]
    : Zero;

  const canFetchLiquidity = useMemo(() => {
    return Boolean(
      liquidityTokenReserves &&
        liquidityTokenReserves[0].gt(Zero) &&
        liquidityTokenReserves[1].gt(Zero)
    );
  }, [liquidityTokenReserves]);

  const walletLiquidity = useWalletLiquidity({
    token0Reserve: liquidityTokenReserves ? token0Reserves : undefined,
    token1Reserve: liquidityTokenReserves ? token1Reserves : undefined,
    walletBalance: accountLiquidityTokenBalance,
    totalSupply: liquidityTokenTotalSupply,
  });

  const walletPoolShare = useWalletPoolShare({
    walletBalance: accountLiquidityTokenBalance,
    totalSupply: liquidityTokenTotalSupply,
  });

  return {
    accountLiquidityTokenBalance,
    refetchAccountLiquidityTokenBalance,
    liquidityTokenTotalSupply,
    refetchLiquidityTokenTotalSupply,
    liquidityTokenReserves,
    refetchLiquidityTokenReserves,
    canFetchLiquidity,
    token0Reserves,
    token1Reserves,
    walletLiquidity,
    walletPoolShare,
  };
};
