import { Icon } from "_components/Icon";
import { IconButtonProps } from "./types";

export const IconButton: React.FC<IconButtonProps> = ({ icon, ...rest }) => {
  return (
    <button {...rest}>
      <Icon icon={icon} />
    </button>
  );
};
