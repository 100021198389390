import clsx from "clsx";
import Link from "next/link";
import { appConfig } from "src/configs/app";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { Icon } from "_components/Icon";
import { Button } from "../Button";
import { Spinner } from "../Spinner";

const polygonScanAddress: Record<number, string> = {
  80001: "mumbai.polygonscan.com",
  137: "polygonscan.com",
};

type TransactionStatus = {
  variant: "pending" | "success" | "error";
  transactionID?: string;
  onClose: () => void;
};

const statusVariant: { [key in TransactionStatus["variant"]]: string } = {
  pending: "border border-primary-600 text-primary-600 bg-primary-600/5",
  success: "border border-success text-success bg-success/5",
  error: "border border-error text-error bg-error/5",
};

const statusMessage: { [key in TransactionStatus["variant"]]: string } = {
  pending: "Your transaction is pending",
  success: "Your transaction was sent successfully",
  error: "Your transaction has failed",
};

export const TransactionStatus: React.FC<TransactionStatus> = ({
  variant,
  transactionID,
  onClose,
}) => {
  const { chainID } = useGlobalContext();

  return (
    <div className="flex flex-col items-center justify-center gap-8 bg-white p-4">
      {variant === "pending" && <Spinner />}
      {variant === "success" && (
        <Icon icon="circle-check" className="h-16 w-16 text-success" />
      )}
      {variant === "error" && (
        <Icon icon="error" className="h-16 w-16 text-error" />
      )}
      <div
        className={clsx(
          "flex items-center justify-center gap-4 rounded-lg p-2",
          statusVariant[variant]
        )}
      >
        {variant === "pending" && <Icon icon="info" className="h-6 w-6" />}
        {variant === "success" && (
          <Icon icon="circle-check" className="h-6 w-6 text-success" />
        )}
        {variant === "error" && (
          <Icon icon="error" className="h-6 w-6 text-error" />
        )}
        <span>{statusMessage[variant]}</span>
      </div>
      <span className="text-primary-700">
        {transactionID && (
          <Link
            href={`https://${
              polygonScanAddress[chainID ?? appConfig.defaultChainId]
            }/tx/${transactionID}`}
            passHref
          >
            <a target="_blank">View transaction on PolygonScan</a>
          </Link>
        )}
      </span>
      <Button variant="primary-dark" onClick={() => onClose()}>
        Close
      </Button>
    </div>
  );
};
