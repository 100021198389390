import { AddressZero, MaxUint256 } from "@ethersproject/constants";
import { Hash } from "@wagmi/core";
import { ABIS } from "constants/abis";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { handleErrorMessage } from "utils/errors";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

export const useAddressApprove = ({
  token,
  value,
  spender,
}: {
  token?: Hash;
  value: BigNumber;
  spender?: Hash;
}): [boolean, (() => void) | undefined, boolean] => {
  const { address, addresses } = useGlobalContext();
  const _spender = spender || addresses?.ROUTER;

  const { config } = usePrepareContractWrite({
    address: token ?? AddressZero,
    abi: ABIS.ERC20,
    functionName: "approve",
    enabled: Boolean(address && token && addresses?.ROUTER),
    args: [_spender ?? AddressZero, MaxUint256],
    onError: (error) => handleErrorMessage(error),
  });

  const {
    write: approveToken,
    isLoading,
    data,
    reset,
  } = useContractWrite(config);

  const { isLoading: transactionIsLoading } = useWaitForTransaction({
    hash: data?.hash,
    enabled: !!data,
    onSettled: () => reset(),
    onError: (error) => handleErrorMessage(error),
  });

  const { data: allowance } = useContractRead({
    address: token ?? AddressZero,
    abi: ABIS.ERC20,
    functionName: "allowance",
    enabled: Boolean(address && token && addresses?.ROUTER),
    args: [address ?? AddressZero, _spender ?? AddressZero],
    onError: (error) => handleErrorMessage(error),
    watch: isLoading || transactionIsLoading,
  });

  const isTokenAllowed = useMemo(() => {
    return allowance ? allowance.gt(value) : false;
  }, [allowance, value]);

  const approveTokenInProgress = isLoading || transactionIsLoading;

  return [isTokenAllowed, approveToken, approveTokenInProgress];
};
