import { Zero } from "@ethersproject/constants";
import { BigNumber, utils } from "ethers";
import { substractSlippage } from "utils/bigNumber";
import { isNumeric } from "_utils/is-numeric";

export enum InputValueActionKind {
  UPDATE = "UPDATE",
}

interface InputValueAction {
  type: InputValueActionKind;
  payload: {
    value: string;
    decimals?: number;
    slippageTolerance: number;
  };
}

interface InputValueState {
  tokenValue: string;
  tokenValueBN: BigNumber;
  tokenMinValueBN: BigNumber;
}

export const defaultState = {
  tokenValue: "0",
  tokenValueBN: Zero,
  tokenMinValueBN: Zero,
};

export const inputValueReducer = (
  state: InputValueState,
  action: InputValueAction
) => {
  const {
    type,
    payload: { value, decimals, slippageTolerance },
  } = action;

  switch (type) {
    case InputValueActionKind.UPDATE:
      const validValue = isNumeric(value);

      if (!validValue) {
        return defaultState;
      }

      const valueBN = utils.parseUnits(value, decimals);

      return {
        tokenValue: value,
        tokenValueBN: valueBN,
        tokenMinValueBN: substractSlippage(valueBN, slippageTolerance),
      };
    default:
      return state;
  }
};
