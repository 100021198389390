import { AddressZero } from "@ethersproject/constants";
import { Hash } from "@wagmi/core";
import { ABIS } from "constants/abis";
import { useMemo } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { handleErrorMessage } from "utils/errors";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

export const usePair = ({
  token0,
  token1,
  enabled = true,
  usePolygon = false,
}: {
  token0?: Hash;
  token1?: Hash;
  enabled?: boolean;
  usePolygon?: boolean;
}) => {
  const { address, addresses, polygonAddresses } = useGlobalContext();
  const _addresses = usePolygon ? polygonAddresses : addresses;

  const args = useMemo(
    () => [token0 ?? AddressZero, token1 ?? AddressZero].sort() as [Hash, Hash],
    [token0, token1]
  );

  const { data: pairAddress, isFetching } = useContractRead({
    address: _addresses?.FACTORY ?? AddressZero,
    abi: ABIS.FACTORY,
    functionName: "getPair",
    enabled: [_addresses, address, enabled, token0, token1].every(Boolean),
    args,
  });

  const pairAddressExists = useMemo(
    () => Boolean(pairAddress && pairAddress !== AddressZero),
    [pairAddress]
  );

  const { config } = usePrepareContractWrite({
    address: addresses?.FACTORY ?? AddressZero,
    abi: ABIS.FACTORY,
    functionName: "createPair",
    enabled: [
      address,
      addresses?.FACTORY,
      enabled,
      !isFetching,
      !pairAddress,
      !pairAddressExists,
      token0,
      token1,
      !usePolygon,
    ].every(Boolean),
    args,
    onError: (error) => handleErrorMessage(error),
  });

  const {
    data,
    isLoading,
    write: createPair,
    reset,
  } = useContractWrite(config);

  const { isLoading: transactionIsLoading } = useWaitForTransaction({
    hash: data?.hash,
    enabled: !!data,
    onError: (error) => {
      reset();
      handleErrorMessage(error);
    },
  });

  const createPairInProgress = isLoading && transactionIsLoading;

  return {
    createPair,
    createPairInProgress,
    pairAddress,
    pairAddressExists,
  };
};
