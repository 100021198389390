import { IconVariants } from "_components/Icon/types";
import { Icon } from "../Icon";
import { IconButton } from "../IconButton";
import { RefreshIconButton } from "../RefreshIconButton";

export const TransactionHeader: React.FC<{
  backTo?: () => void;
  canRefresh?: boolean;
  icon: IconVariants;
  openTransactionSettings?: () => void;
  refresh?: () => void;
  title: string;
}> = ({
  backTo,
  canRefresh = false,
  icon,
  openTransactionSettings,
  refresh,
  title,
}) => {
  return (
    <div className="grid grid-cols-[1fr_auto_1fr] content-center">
      <div className="flex items-center">
        {backTo && <IconButton icon="back" onClick={() => backTo()} />}
      </div>
      <div className="flex place-items-center gap-2 text-lg font-medium">
        <Icon icon={icon} className="text-primary-600" /> {title}
      </div>
      <div className="flex items-center justify-end gap-2">
        {refresh ? (
          canRefresh ? (
            <RefreshIconButton onClick={() => refresh()} />
          ) : (
            <Icon
              icon="refresh-disabled"
              className="cursor-not-allowed text-functional-300"
            />
          )
        ) : null}
        {openTransactionSettings && (
          <IconButton
            icon="settings"
            onClick={() => openTransactionSettings()}
          />
        )}
      </div>
    </div>
  );
};
