import { useEffect } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { useTokenBySymbol, useValidateTokenSymbols } from "_hooks/token";

export const useTokenQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    token0: withDefault(StringParam, "USDC"),
    token1: withDefault(StringParam, "FID"),
  });

  const setToken0 = (token: string) => setQuery({ token0: token });
  const setToken1 = (token: string) => setQuery({ token1: token });

  const { token0: _token0, token1: _token1 } = useValidateTokenSymbols(
    query.token0,
    query.token1
  );

  const reverseTokens = () =>
    setQuery({ token0: query.token1, token1: query.token0 });

  useEffect(() => {
    if (_token0 !== query.token0 || _token1 !== query.token1) {
      setQuery({ token0: _token0, token1: _token1 });
    }
  }, [_token0, _token1, query.token0, query.token1, setQuery]);

  const token0 = useTokenBySymbol(_token0);
  const token1 = useTokenBySymbol(_token1);

  return { token0, token1, setToken0, setToken1, reverseTokens };
};
