import { AddressZero } from "@ethersproject/constants";
import type { QueryObserverResult } from "@tanstack/react-query";
import { FetchBalanceResult, Hash } from "@wagmi/core";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { useBalance } from "wagmi";

export const useTokenBalance = ({
  token,
  value,
}: {
  token?: Hash;
  value?: BigNumber;
}): [
  FetchBalanceResult | undefined,
  boolean,
  () => Promise<QueryObserverResult<FetchBalanceResult, Error>>
] => {
  const { address } = useGlobalContext();

  const { data: balance, refetch: refetchBalance } = useBalance({
    address,
    token,
    enabled: Boolean(address && token && token !== AddressZero),
  });

  const hasEnoughBalance = useMemo(() => {
    return balance && value ? value.lte(balance.value) : false;
  }, [balance, value]);

  return [balance, hasEnoughBalance, refetchBalance];
};
